import 'bootstrap';
import 'js/jquery.cookie';
import 'js/jquery.awesomeCloud';
import 'js/jquery.equalheights';
import 'js/rating';
import 'js/theme';
import $ from 'jquery';
import nowAndLater from '@digicomp/now-and-later'

window.jQuery = $;

nowAndLater('.photopile', () => {
    import('./photopile')
})
